import React, { useContext, useState } from "react";
import { Drawer } from "@material-ui/core";

import { LibraryHeader, NewTitle, Container } from "./styles/libraryStyle";
import { PrintButton, TitleWrapper } from "./styles/editStyle";
import { BackBtn } from "./styles/commonStyle";
import { ContentContext, MainContext } from "../context";
import { handleHistoryPush, handleHistoryReplace } from "../utils/history";
import { MainWrapper, PrintedTitle } from "./styles/IntroStyle";
import { Phrase } from "./Phrase";
import { MacaronsContainer, MacaronsRow } from "./MacaronSelection/styles";
import { ActionButtons } from "./home/index";
import { useLoadLibrary } from "../services/hooks";
import DeleteModal from "./MacaronSelection/modals/DeleteModal";
import DuplicateModal from "./MacaronSelection/modals/DuplicateModal";
import { createMacaronRipple } from "./MacaronSelection/utils/createMacaronRipple";
import { uploadModule } from "../actions";
import { errors } from "./EditImage";
import { Loader } from "./styles/loader";
import { BadConnectionMsg, BadLocationMsg } from "./messages";
import TwoIcon from "./MacaronSelection/icons/TwoIcon";
import ThreeIcon from "./MacaronSelection/icons/ThreeIcon";
import OneIcon from "./MacaronSelection/icons/OneIcon";
import MacaronButton from "./MacaronSelection/MacaronButton";
import MacaronsModalLeft from "./MacaronSelection/modals/MacaronsLeftModal";
import { blobToBase64 } from "./common/imageBlobHelper";
import { GaEvents, sendGAEvent } from "../utils/googleAnalytics";

const titleHeight = 50;

const MacaronSelection = (props) => {
  const { iFrame, macarons: selectedDeisgns = {}, macaronAmount, reactNativeFlow } = (props.location && props.location.state) || {};
  const [isMacaronModalOpen, setIsMacaronModalOpen] = useState(false)
  const [selectedMacaron, setSelectedMacaron] = useState();
  const [isOptionsOpen, setIsOptionsOpen] = useState({ '1': false, '2': false, '3': false })
  const [macarons, setMacarons] = useState(selectedDeisgns)
  const [selectedMacaronActions, setSelectedMacaronActions] = useState();
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const [error, setError] = useState();

  const { content } = useContext(ContentContext);

  const tokenHeader = `android_1.17_fAxTeOoIu1HoZRmrBgCKzCSBamFls7Z4mp3ioUIyEEe8o9CTDweURADrWZ3N`;

  const { ripples, surprise } = useLoadLibrary(props);
  const { main } = useContext(MainContext);

  if (!content) {
    handleHistoryReplace(props.history, '/home')
    return null;
  }

  const { general = {}, macaron = {}, edit = {} } = content;
  const { selectMacaronToDesign = {} } = macaron.texts || {};
  const { printButton: printButtonStyle } = edit.buttons;

  const buttonTextColor = content.home.buttons.snap ? content.home.buttons.snap.textColor : content.home.buttons.library.textColor;
  const loaderColor = buttonTextColor;
  const generalColor = general.colors.generalTextColor;
  const backgroundColor = general.colors.backgroundColor;

  const handleOptionsClick = (macaronId) => {
    setSelectedMacaronActions(macaronId);
    setIsOptionsOpen(isOptionsOpen => ({ ...isOptionsOpen, [macaronId]: true }))
  }

  const handleOptionsClose = (macaronId) => {
    setIsOptionsOpen(isOptionsOpen => ({ ...isOptionsOpen, [macaronId]: false }))
  }

  const handleBack = () => {
    if (main.preview) {
      handleHistoryReplace(props.history, "/preview");
      return;
    }

    handleHistoryPush(props.history, "/home");
  }

  const handleMacaronClick = (macaronId) => {
    setSelectedMacaron(macaronId)
    setIsMacaronModalOpen(true);
  }

  const handleCreateText = () => {
    handleHistoryPush(props.history, '/edit', {
      ...props.location.state,
      macaronId: selectedMacaron,
      macarons,
      macaronAmount,
      iFrame: !!props.iFrame,
    })
  }

  const handleRippleClick = () => {
    sendGAEvent(GaEvents.FlowSelection, {
      eventCategory: "flow",
      eventLabel: 'Macaron_from_design',
      value: 'Macaron_from_design',
      locationId: content.locationId,
    });

    handleHistoryPush(props.history, '/library', {
      ...props.location.state,
      macaronId: selectedMacaron,
      macarons,
      macaronAmount,
      iFrame: !!props.iFrame
    })
  }

  const handleSurpriseClick = () => {
    sendGAEvent(GaEvents.FlowSelection, {
      eventCategory: "flow",
      eventLabel: 'Macaron_surprise_me',
      value: 'Macaron_surprise_me',
      locationId: content.locationId,
    });
    handleHistoryPush(props.history, '/surprise', {
      ...props.location.state,
      macaronId: selectedMacaron,
      macarons,
      macaronAmount,
    })
  }

  const handleMacaronDelete = (macaronId) => {
    setIsDeleteModalOpen(true)
    handleOptionsClose(macaronId);
  }

  const handleMacaronDuplicate = (macaronId) => {
    setIsDuplicateModalOpen(true)
    handleOptionsClose(macaronId)
  }

  const handleMacaronDeleteConfirm = (macaronId) => {
    setMacarons(macarons => ({ ...macarons, [macaronId]: {} }))
    setIsDeleteModalOpen(false)
    handleOptionsClose(macaronId)
  }

  const handleMacaronDuplicateConfirm = (macaronId) => {
    if (macaronAmount === 2) {
      setMacarons(macarons => ({ '1': macarons[macaronId], '2': macarons[macaronId] }))
    } else {
      setMacarons(macarons => ({ '1': macarons[macaronId], '2': macarons[macaronId], '3': macarons[macaronId] }))
    }
    setIsDuplicateModalOpen(false)
    handleOptionsClose(macaronId)
  }

  const macaronsToGo = macaronAmount - Object.values(macarons).filter(value => !!value && Object.keys(value).length > 0).length;

  const handlePrintClick = async () => {
    if ((macaronsToGo === macaronAmount) || isPrintLoading) return;
    if (macaronsToGo > 0) {
      setIsPrintModalOpen(true);
      return;
    }

    await handlePrint();
  }

  const handlePrint = async () => {
    if (main.preview) {
      handleHistoryReplace(props.history, "/result", {
        number: 25,
        mobileQData: "",
      });
      return;
    }

    setIsPrintLoading(true)
    try {
      if (!navigator.onLine) throw new Error();
      const blob = await createMacaronRipple({
        macaron1Src: macarons['1'] ? macarons['1'].image : undefined,
        macaron2Src: macarons['2'] ? macarons['2'].image : undefined,
        macaron3Src: macarons['3'] ? macarons['3'].image : undefined
      });

      if (!!reactNativeFlow) {
        const imageBase64 = await blobToBase64(blob);
        window.ReactNativeWebView.postMessage(JSON.stringify({
          imageBase64,
          type: 'IMAGE_BLOB',
          isMacaroon: true
        }));
        if (reactNativeFlow === 'CreateDesign')
          return;
      }

      if (iFrame) {
        const imageBase64 = await blobToBase64(blob);
        const message = JSON.stringify({
          imageBase64,
          type: "image",
          isMacaroon: true
        });
        window.parent.postMessage(message, "*");
        setIsPrintLoading(false);
        return;
      }


      const mobileQData =
        await uploadModule({ locationState: props.location.state, content, tokenHeader, croppedImg: blob, isMacaroon: true }).catch((err) => {
          setIsPrintLoading(false);
          setError(err.errorCode || errors.connection);
        })

      if (!mobileQData) return;

      if (props.location.state.demo) {
        const imageUrl = mobileQData.match("^http://") ? mobileQData.replace("http://", "https://") : mobileQData;
        const image = new Image();
        image.src = imageUrl;

        image.onload = () => {
          setIsPrintLoading(false);
          handleHistoryPush(props.history, "/result-demo", {
            number: mobileQData.ordinal || 1,
            mobileQData: imageUrl,
          });
        };
      } else {
        setIsPrintLoading(false);
        handleHistoryPush(props.history, "/result", {
          number: mobileQData.ordinal || 1,
          imageUrl: mobileQData.rippleUri,
        });
      }
    } catch (error) {
      setIsPrintLoading(false);
      setIsPrintModalOpen(false)
      setError(error.errorCode || errors.connection);
    } finally {
      setIsPrintLoading(false)
    }
  }

  return (
    <MainWrapper>
      <Container style={{ alignItems: 'center', backgroundColor }}>
        <LibraryHeader>
          <TitleWrapper titleHeight={titleHeight}>
            {!iFrame && <BackBtn onClick={handleBack} colors={general.colors} />}
            <NewTitle colors={general.colors}>
              <Phrase
                Key={"3960a161-f642-4052-9fa7-04f609d4a367"}
                default={`${macaronAmount} Macarons`}
                replaceValues={[{ searchValue: '{{x}}', replaceWith: macaronAmount }]} />
            </NewTitle>
            <div style={{ width: "10%" }} />
          </TitleWrapper>
        </LibraryHeader>
        <PrintedTitle id="printed" data={{ ...selectMacaronToDesign, color: generalColor }} style={{ width: '80%' }}>
          <Phrase Key={selectMacaronToDesign.translationKey} default={selectMacaronToDesign.text || ""} />
        </PrintedTitle>
        <MacaronsContainer>
          <MacaronsRow style={{ justifyContent: 'center' }}>
            <MacaronButton
              macaronInfo={macarons['1']}
              macaronId={'1'}
              isOptionsOpen={isOptionsOpen['1']}
              Icon={OneIcon}
              onOptionsClick={() => handleOptionsClick('1')}
              onMacaronDelete={() => handleMacaronDelete('1')}
              onOptionsClose={() => handleOptionsClose('1')}
              onMacaronClick={() => handleMacaronClick(1)}
              onDuplicate={() => handleMacaronDuplicate('1')}
              content={content}
            />
          </MacaronsRow>
          <MacaronsRow style={{ justifyContent: macaronAmount === 3 ? 'space-between' : 'flex-start' }}>
            <MacaronButton
              macaronInfo={macarons['2']}
              macaronId={'2'}
              isOptionsOpen={isOptionsOpen['2']}
              Icon={TwoIcon}
              onOptionsClick={() => handleOptionsClick('2')}
              onMacaronDelete={() => handleMacaronDelete('2')}
              onOptionsClose={() => handleOptionsClose('2')}
              onMacaronClick={() => handleMacaronClick(2)}
              content={content} />

            <MacaronButton
              macaronInfo={macarons['3']}
              macaronId={'3'}
              isOptionsOpen={isOptionsOpen['3']}
              Icon={ThreeIcon}
              onOptionsClick={() => handleOptionsClick('3')}
              onMacaronDelete={() => handleMacaronDelete('3')}
              onOptionsClose={() => handleOptionsClose('3')}
              onMacaronClick={() => handleMacaronClick(3)}
              content={content}
              renderCondition={macaronAmount === 3} />
          </MacaronsRow>
        </MacaronsContainer>

        <PrintButton id="printButton" data={printButtonStyle} onClick={handlePrintClick} style={{ margin: 'auto 0 40px 0' }}>
          {(isPrintLoading && !isPrintModalOpen) ? (
            <Loader color={loaderColor} />
          ) : (
            iFrame || reactNativeFlow === 'CreateDesign' ?
              <Phrase Key={reactNativeFlow === 'CreateDesign' ? "" : "c3d83da1-ba25-42cb-81d1-4701d62cd40d"} default={reactNativeFlow === 'CreateDesign' ? "Add To Folder" : "CREATE"} /> :
              <Phrase Key={printButtonStyle ? printButtonStyle.translationKey : "38a49a49-6a98-4dad-bd1f-e678aa904ff0"} default={printButtonStyle.text || ""} />
          )}
        </PrintButton>
      </Container>
      <Drawer open={isMacaronModalOpen} onClose={() => setIsMacaronModalOpen(false)} anchor="bottom" PaperProps={{ style: { borderTopLeftRadius: '20px', borderTopRightRadius: '20px', backgroundColor: backgroundColor } }} BackdropProps={{ style: { backgroundColor: '#000000cc' } }}>
        <PrintedTitle id="printed" data={{}} style={{ margin: '20px 0', color: generalColor, textTransform: "unset", fontFamily: "MuktaRegular" }}>
          <Phrase Key={"8b95bc14-44f0-4b4d-9891-1a1b47a14343"} default={`Macaron No. ${selectedMacaron}`} replaceValues={[{ searchValue: '{{x}}', replaceWith: selectedMacaron }]} />
        </PrintedTitle>
        <ActionButtons iFrame={iFrame} content={content} ripples={ripples} surprise={surprise} {...{ ...props, ...props.location.state, macaronId: selectedMacaron, macarons, macaronAmount, iFrame: !!props.iFrame }} isPreview={main.preview} onCreateText={handleCreateText} onSelectRipple={handleRippleClick} onSurpriseClick={handleSurpriseClick} style={{ margin: '20px 0' }} isMacaron={true} />
      </Drawer>
      {error === errors.location && <BadLocationMsg data={content.messages.texts.badLocation} back={general.colors} />}
      {error === errors.connection && <BadConnectionMsg data={content.messages.texts.connectionError} setError={setError} back={general.colors} />}
      {isDeleteModalOpen && <DeleteModal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} onConfirm={() => handleMacaronDeleteConfirm(selectedMacaronActions)} rippleSrc={macarons[selectedMacaronActions].image} content={content} isSurprise={macarons[selectedMacaronActions].isSurprise} />}
      {isDuplicateModalOpen && <DuplicateModal isOpen={isDuplicateModalOpen} onClose={() => setIsDuplicateModalOpen(false)} onConfirm={() => handleMacaronDuplicateConfirm(selectedMacaronActions)} rippleSrc={macarons[selectedMacaronActions].image} isSurprise={macarons[selectedMacaronActions].isSurprise} macaronAmount={macaronAmount} content={content} />}
      {isPrintModalOpen && <MacaronsModalLeft isOpen={isPrintModalOpen} onClose={() => setIsPrintModalOpen(false)} onConfirm={handlePrint} content={content} macaronAmount={macaronAmount} macaronsToGo={macaronsToGo} isPrintLoading={isPrintLoading} />}
    </MainWrapper>
  );
};

export default MacaronSelection;